import classNames from "classnames";
import React, { ReactNode } from "react"

type ProjectHeroProps = {
  agency: string;
  background?: string;
  body: ReactNode[];
  engagement?: string;
  technologies?: string;
  position?: string;
  project: {
    label: string;
  };
  subtitle: string;
  title: string;
  url?: {
    href: string;
    label: string;
  };
}

function ProjectHero({ agency, background, body, engagement, position, project, subtitle, technologies, title, url }: ProjectHeroProps) {

  return (
    <header data-background={background || 'purple'} className="py-28 md:py-48 px-5 md:px-[2.25vw] lg:px-[3vw] section-project-hero">
      <div>
        <h1 className="font-black font-sans leading-[14vw] md:leading-none mb-8 text-[12.1875vw] md:text-[10.15625vw] lg:text-[7.6171875vw] xl:text-[6.09375vw] text-white">{project.label}</h1>
        <h2 className="font-thin font-sans leading-snug mb-12 md:mb-24 text-[22px] sm:text-[3.4375vw] md:text-[2.86458333333vw] lg:text-[2.1484375vw] xl:text-[1.875vwvw] text-white">{subtitle}.</h2>
        <div className="lg:flex">
          <div className="mb-8 lg:mb-0 w-full lg:w-4/12">
            {project && project.label && (
              <div className="font-light font-sans mb-2 text-[10px] sm:text-xs tracking-wider text-white/80 uppercase">
                Client: {project.label}
              </div>
            )}
            {engagement && (
              <div className="font-light font-sans mb-6 text-[10px] sm:text-xs tracking-wider text-white/80 uppercase">
                Engagement: {engagement}
              </div>
            )}
           
            {url && (
              <div className="font-light font-sans mb-2 text-[10px] sm:text-xs tracking-wider text-white/80 uppercase">
                Live Project: <a href={url.href} target="_blank" className="inline-block text-white">{url.label}</a>
              </div>
            )}
            {position && (
              <div className="font-light font-sans mb-2 text-[10px] sm:text-xs tracking-wider text-white/80 uppercase">
                Position: {position}
              </div>
            )}
            {technologies && (
              <div className="font-light font-sans leading-normal mt-6 text-[10px] sm:text-xs tracking-wider text-white/80 uppercase">
                  <div className="mb-1">Technologies:</div>
                  {technologies}
              </div>
            )}    
          </div>
          <div className="w-full lg:w-8/12">
            {title && (
              <>
                <div className="font-light font-mono mb-4 text-[10px] sm:text-[1.5625vw] md:text-[1.30208333333vw] lg:text-[0.9765625vw] xl:text-[0.78125vw] text-white/80">
                  {title}.
                </div> 
                <div className="text-white">
                  {body && body.length && body.map((item, i) => 
                    <p 
                      key={`project-hero__${i}`} 
                      className={classNames(`font-thin font-sans leading-relaxed mb-6 md:mb-8 `, {
                        'text-[16px] sm:text-[2.5vw] md:text-[2.08333333333vw] lg:text-[1.5625vw] xl:text-[1.25vw]': i === 0,
                        'text-[14px] sm:text-[2.1875vw] md:text-[1.82291666667vw] lg:text-[1.3671875vw] xl:text-[1.09375vw]': i !== 0
                      })}
                    >{item}</p>)}
                </div>
              </>
            )} 
          </div>
        </div>
      </div>
    </header>
  )
}

export default ProjectHero
