import React, { useEffect } from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { SplitText } from "gsap/SplitText"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"

import "../../styles/tgi-fridays.css"
import ProjectHero from "../../components/ProjectHero"

const TGIFridaysChristmasPage = (props: PageProps) => {
  gsap.config();
  gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger, SplitText);

  ScrollTrigger.defaults({ ignoreMobileResize: true });

  useEffect(() => {
    const root = document.querySelector('body');

    if (!root) {
        return
    }

        const calculateStyle = (dimension) => {
            return `${dimension + (dimension / 2)}px`
        }

            const transitionElement = root.querySelector('.tgi-fridays-page-transition');
            let winHeight = window.innerHeight;
            let winWidth = window.innerWidth;

            if (!transitionElement) {
                const tag = document.createElement('div');
                tag.classList.add('tgi-fridays-page-transition');
                tag.innerHTML = `
                    <div class="tgi-fridays-page-transition__slide"><span></span></div>
                    <div class="tgi-fridays-page-transition__slide"><span></span></div>
                    <div class="tgi-fridays-page-transition__slide"><span></span></div>
                    <div class="tgi-fridays-page-transition__slide"><span></span></div>
                    <div class="tgi-fridays-page-transition__slide"><span></span></div>
                `;

                if (winHeight > winWidth) {
                    tag.style.height = calculateStyle(winHeight);
                } else {
                    tag.style.width = calculateStyle(winWidth);
                }
                root.appendChild(tag);

                window.addEventListener('resize', () => {
                    const newWinHeight = window.innerHeight;
                    const newWinWidth = window.innerWidth;

                    if (newWinWidth !== winWidth || newWinHeight !== winHeight) {
                        tag.style.width = '';
                        tag.style.height = '';

                        if (newWinHeight > newWinWidth) {
                            tag.style.height = calculateStyle(newWinHeight);
                        } else {
                            tag.style.width = calculateStyle(newWinWidth);
                        }
                        winHeight = newWinHeight;
                        winWidth = newWinWidth;
                    } 
                })
        }
  }, [])

  const toggleTransition = () => {
        document.body.classList.add('transition-page-out');
        setTimeout(() => {
            document.body.classList.remove('transition-page-out');
            document.body.classList.add('transition-page-is-out');
        }, 2000);
        setTimeout(() => {
            document.body.classList.add('transition-page-in');
        }, 3000);
        setTimeout(() => {
            document.body.classList.remove('transition-page-is-out');
            document.body.classList.remove('transition-page-in');
        }, 5000);
  }

  return (
    <Layout page="work" route="/portfolio/tgi-fridays-christmas">
      <Seo 
        image={'/tgi-fridays/cover-christmas.jpg'}
        title="TGI Fridays Christmas by Alistair Bancroft"
        description={'Enhance user experience by introducing a playful present wrapping page transition on the TGI Fridays website.'}
      />
      <div className="tgi-fridays-namespace">

        <ProjectHero
          background="tgiFridays"
          agency={'Inviqa'}
          body={[
            "To infuse a touch of holiday magic into the TGI Fridays website, we experimented with a seamless page transition that encapsulated the festive spirit.",
            "As you click your way to explore the websites offerings, a playful present wrapping page transition unfolds before your eyes. This whimsical touch not only adds a touch of excitement but also encapsulates the spirit of celebration that TGI Fridays is known for. It's as if each menu item is a delightful surprise waiting to be unwrapped.", 
            "Click on the button below to see the transition in action."]}
          engagement={'2023'}
          technologies={'React, TailwindCSS'}
          position={'Senior Front End / Inviqa'}
          project={{
            label: 'TGI FRIDAYS',
          }}
          title={'Playground: TGI Fridays Christmas'}
          subtitle={'Experimenting with a playful present wrapping page transition on the TGI Fridays website'}
        />

        <section data-background="tgiFridays" className="flex justify-end -mt-24 md:-mt-44 mb-28 pb-5 px-5 md:px-[2.25vw] lg:px-[3vw]">
            <div className="w-full lg:w-8/12">
                <button onClick={() => toggleTransition()}
                className="tgi-fridays__btn tgi-fridays__btn--primary">
                    <div className="tgi-fridays__btn__inner split-text" data-rotate="45deg" data-x="-10" data-y="10">
                        <div className="tgi-fridays__btn__icon ml-2">
                        <svg className="stroke-current" viewBox="0 0 24 24" fill="none">
                            <path d="M21.9478 12.1251L1 12.1719" strokeWidth="3.5" strokeMiterlimit="10"></path>
                            <path d="M22.5462 12.125C15.3642 12.125 10.8754 19.3071 9.07983 22.0004" strokeWidth="3.5" strokeMiterlimit="10"></path>
                            <path d="M22.5462 12.125C15.3642 12.125 10.8754 4.94291 9.07983 2.24963" strokeWidth="3.5" strokeMiterlimit="10"></path>
                        </svg>
                        </div>
                        <span>Click to animate</span>
                    </div>
                </button>
            </div>
        </section>


        
      </div>
    </Layout>
  )
}

export default TGIFridaysChristmasPage
